// Footer.js

import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-transparent py-4 text-center text-gray-600">
        <p>Derechos Reservados 2024 TheCompany.mx</p>
        <p>Whats App: +52 811 982 1980</p>
        </footer>
    );
};

export default Footer;
