import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = ({ phoneNumber }) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;

    return (
        <a href={whatsappUrl} target="_blank" rel="noopener noreferrer"
            className="fixed bottom-4 right-4 bg-dark p-3 rounded-full shadow-lg text-light hover:bg-accent hover:bg-opacity-50 transition-all duration-300 cursor-pointer animate-float animate-glow">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
    );
};

export default WhatsAppButton;
