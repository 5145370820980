import './global.css';
import Navbar from './Components/Navbar/Navbar';
import VideoSection from './Components/VideoSection/VideoSection';
import ServicesSection from './Components/ServicesSection/ServicesSection';
import AboutUsSection from './Components/AboutUsSection/AboutUsSection';
import ContactUsSection from './Components/ContactUsSection/ContactUsSection';
import Footer from './Components/Footer/Footer';
import WhatsAppButton from './Components/WhatsAppButton/WhatsAppButton';
import ClientsSection from './Components/ClientsSection/ClientsSection';

function App() {
  return (
    <div className="bg-secondary">
      <Navbar />
      <VideoSection />
      <ServicesSection />
      <AboutUsSection />
      <ClientsSection />
      <ContactUsSection />
      <Footer />
      <WhatsAppButton phoneNumber="+528119821980" />
    </div>
  );
}

export default App;
