import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUsSection = () => {
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        toast.success('Información enviada correctamente');
        setTimeout(() => {
            e.target.submit(); // Submit the form programmatically after showing the toast
        }, 2000); // Adjust the delay as needed
    };

    return (
        <section id="contact-us" className="bg-transparent py-16">
            <div className="container mx-auto">
                <div className="max-w-md mx-auto bg-transparent p-8 rounded-lg shadow-md text-accent">
                    <h2 className="text-3xl font-bold mb-8">Contáctanos por correo o WhatsApp</h2>
                    <h3 className="text-xl font-bold mb-8 text-tertiary">Incluye tus dudas o si deseas una cotización y en seguida nos pondremos en contacto:</h3>
                    <form onSubmit={handleSubmit} action="https://formsubmit.co/5c428fed3b4f9c12f69ba8b0663ee371" method="POST">
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-sm font-medium mb-2 text-primary">
                                Nombre y Apellido:
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className="w-full p-2 border border-light rounded text-light bg-dark"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium mb-2">
                                Correo Electrónico:
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="w-full p-2 border border-light rounded text-light bg-dark"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="subject" className="block text-sm font-medium mb-2">
                                Asunto:
                            </label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                className="w-full p-2 border border-light rounded text-light bg-dark"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="comments" className="block text-sm font-medium mb-2">
                                Comentarios:
                            </label>
                            <textarea
                                id="comments"
                                name="comments"
                                rows="4"
                                className="w-full p-2 border border-light rounded text-light bg-dark"
                            ></textarea>
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className="bg-dark text-light py-2 px-4 rounded hover:bg-tertiary hover:scale-105 transition-all duration-300"
                            >
                                Enviar
                            </button>
                        </div>
                        <input type="hidden" name="_next" value="https://thecompany.mx" />
                        {/*<input type="hidden" name="_captcha" value="false"/>*/}
                    </form>
                    <ToastContainer />
                </div>
            </div>
        </section>
    );
};

export default ContactUsSection;
