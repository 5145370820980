import React from 'react';
import image_about_us from './1.png';

const AboutUsSection = () => {
    return (
        <section id="about-us" className="bg-transparent py-16">
            <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
                <div className="md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
                    <img
                        className="w-full h-auto object-cover rounded-lg shadow-lg"
                        src={image_about_us}
                        alt="Imagen de About Us"
                    />
                </div>
                <div className="md:w-1/2">
                    <h2 className="text-3xl md:text-4xl lg:text-6xl font-bold mb-4 text-accent">Quienes somos:</h2>
                    <p className="text-lg text-tertiary mb-4">
                        Somos Ingenieros dedicados al avance tecnológico. 
                        Nuestro objetivo es simplificar y ser el puente que conecte tu idea y la realidad de un sitio web. 
                        Comprendemos las inquietudes de aquellos que desconocen por dónde comenzar,
                        y trabajamos de manera colaborativa y personalizada para materializar sus ideas en el ámbito digital.
                    </p>
                    <p className="text-lg text-tertiary">
                        Nos consideramos lideres en nuestra rama ya que nuestro principal virtud es el tiempo, tenemos la mentalidad de que el tiempo es
                        primordial para nuestros clientes.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default AboutUsSection;
