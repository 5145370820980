// ServiceCard.js

import React from 'react';

const ServiceCard = ({ imageUrl, title, description }) => {
    return (
        <div className="bg-dark p-6 rounded-lg shadow-md transition duration-500 ease-in-out transform hover:scale-105 hover:shadow-xl">
        <img
            className="w-70 h-70 object-cover mx-auto mb-4"
            src={imageUrl}
            alt={title}
        />
        <h3 className="text-accent text-4xl font-semibold mb-2">{title}</h3>
        <p className="text-lg text-tertiary">{description}</p>
        </div>
    );
};

export default ServiceCard;
