import React from 'react';
import ServiceCard from '../ServiceCard/ServiceCard';
import service_Image_1 from './1_serivices.png';
import service_Image_2 from './2.webp';
import service_Image_3 from './3.png';

const ServicesSection = () => {
    return (
        <section id="servicios" className="bg-transparent py-16 relative">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold mb-8 text-accent text-center">Nuestros Servicios</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <ServiceCard
                    imageUrl={service_Image_1}
                    title="Web Site Custom"
                    description="Impulsa tu negocio con nuestras soluciones web personalizadas. Diseño y desarrollo adaptados a tus metas, garantizando rendimiento y seguridad. Transformamos tus ideas en una presencia digital impactante"
                    />
                    <ServiceCard
                    imageUrl={service_Image_2}
                    title="Web Site Creator"
                    description="Creamos tu Web Site con las solucion que quieras respaldada por terceros como Wix, Shopify, WordPress, etc."
                    />
                    <ServiceCard
                    imageUrl={service_Image_3}
                    title="Web Upgrate"
                    description="Ofrecemos mejoras técnicas y visuales para garantizar un diseño moderno y un rendimiento optimizado. Mantenga su presencia en línea actualizada y atractiva con nuestro servicio de actualización profesional."
                    />
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
