import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import video from './VideoSection-2.mp4';


const VideoSection = () => {
    const phrases = [
    "If you can dream it, we can build it",
    "Transforming ideas into reality",
    "Innovate, Create, Inspire",
        // Agrega más frases según sea necesario
    ];

    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsVisible(false); // Ocultar el contenedor antes de cambiar la frase
            setTimeout(() => {
                setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
                setIsVisible(true); // Mostrar el contenedor después de cambiar la frase
            }, 1000); // Esperar 500ms antes de cambiar la frase
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);
    


    return (
        <section id="home" className="relative h-screen overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
            <motion.video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
            >
            <source src={video} type="video/mp4" />
            </motion.video>
        </div>
        {/*<div className="absolute inset-0 flex items-center justify-end">*/}
        <div className={`absolute inset-0 flex items-center justify-end transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className="text-right max-w-2xl mx-8">
            {/*<h2 className="text-accent text-7xl font-bold mb-4">The Company</h2>*/}
            <p className="text-accent text-7xl font-bold mb-4">
                {phrases[currentPhraseIndex]}
            </p>
            </div>
        </div>
        </section>
    );
};

export default VideoSection;
