import React from 'react';
import cliente1 from './1.png';
import cliente2 from './2.png';
import cliente3 from './3.png';

const clients = [
    {
        url: 'https://occasion.com.mx',
        image: cliente1,
        alt: 'Cliente 1'
    },
    {
        url: 'https://www.pesaymexico.com',
        image: cliente2,
        alt: 'Cliente 2'
    },
    {
        url: 'https://www.chipinque.org.mx',
        image: cliente3,
        alt: 'Cliente 3'
    }
  // Agrega más clientes según sea necesario
];

const ClientsSection = () => {
    return (
        <section className="py-16 bg-transparent">
            <div className="container mx-auto px-4 relative">
                <h2 className="text-3xl md:text-4xl lg:text-5xl mb-8 text-accent">Colaboraciones:</h2>
                <div className="relative overflow-hidden h-32">
                    <div className="flex gap-x-8 md:gap-x-16 lg:gap-x-32 animate-scroll whitespace-nowrap">
                        {clients.map((client, index) => (
                        <a href={client.url} target="_blank" rel="noopener noreferrer" key={index} className="inline-block flex-shrink-0">
                            <img src={client.image} alt={client.alt} className="h-10 w-50  rounded-lg transition duration-500 ease-in-out transform hover:scale-110 hover:shadow-xl my-4" />
                        </a>
                        ))}
                        {clients.map((client, index) => (
                        <a href={client.url} target="_blank" rel="noopener noreferrer" key={`${index}-duplicate`} className="inline-block flex-shrink-0">
                            <img src={client.image} alt={client.alt} className="h-10 w-50  rounded-lg transition duration-500 ease-in-out transform hover:scale-110 hover:shadow-xl my-4" />
                        </a>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ClientsSection;