import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from './logo_navbar.png';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    const handleMenuClick = () => {
        setShowMenu(!showMenu);
    };

    useEffect(() => {
        const handleResize = () => {
        if (window.innerWidth > 520 && showMenu) {
            setShowMenu(false);
        }
        };

        window.addEventListener("resize", handleResize);

        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, [showMenu]);

    const handleLinkClick = () => {
        setShowMenu(false);
    };

    return (
        <nav className="bg-secondary opacity-85 fixed top-0 left-0 right-0 flex justify-between items-center p-4 z-10">
        <div className="flex items-center">
            <img src={logo} alt="Mi logo" className="w-10 h-10 mr-4" />
            <Link to="https://TheCompany.mx" className="text-5xl text-accent capitalize">
            The Company
            </Link>
        </div>
        <div className="hidden md:flex space-x-4">
            <button
            onClick={() => {
                document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
            }}
            className="px-4 py-2 rounded-md text-primary hover:bg-opacity-50 hover:bg-accent hover:scale-105 transition-all duration-300"
            >
            HOME
            </button>
            <button
            onClick={() => {
                document.getElementById('servicios').scrollIntoView({ behavior: 'smooth' });
            }}
            className="px-4 py-2 rounded-md text-primary hover:bg-opacity-50 hover:bg-accent hover:scale-105 transition-all duration-300"
            >
            SERVICIOS
            </button>
            <button
            onClick={() => {
                document.getElementById('about-us').scrollIntoView({ behavior: 'smooth' });
            }}
            className="px-4 py-2 rounded-md text-primary hover:bg-opacity-50 hover:bg-accent hover:scale-105 transition-all duration-300"
            >
            ABOUT US
            </button>
            <button
            onClick={() => {
                document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
            }}
            className="px-4 py-2 rounded-md text-primary hover:bg-opacity-50 hover:bg-accent hover:scale-105 transition-all duration-300"
            >
            CONTACTANOS
            </button>
        </div>
        <div className="md:hidden flex items-center">
            <button
            onClick={handleMenuClick}
            className="text-primary p-2 rounded-md focus:outline-none hover:bg-opacity-50 hover:bg-accent hover:scale-105 transition-all duration-300"
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
                />
            </svg>
            </button>
        </div>
        <div
            className={`fixed top-0 right-0 w-2/3 h-full bg-secondary transition-all duration-700 z-50 transform ${
            showMenu ? 'translate-x-0' : 'translate-x-full'
            }`}
        >
            <div className="flex flex-col items-center justify-center h-full">
            <button
                onClick={handleMenuClick}
                className="absolute top-4 right-4 text-white p-2 rounded-full focus:outline-none hover:bg-opacity-50 hover:bg-accent hover:scale-105 transition-all duration-300"
            >
                <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <button
                onClick={() => {
                document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
                handleLinkClick();
                }}
                className="block px-4 py-2 text-primary hover:bg-opacity-50 hover:bg-accent rounded-md hover:scale-105 transition-all duration-300"
            >
                HOME
            </button>
            <button
                onClick={() => {
                document.getElementById('servicios').scrollIntoView({ behavior: 'smooth' });
                handleLinkClick();
                }}
                className="block px-4 py-2 text-primary hover:bg-opacity-50 hover:bg-accent rounded-md hover:scale-105 transition-all duration-300"
            >
                SERVICIOS
            </button>
            <button
                onClick={() => {
                document.getElementById('about-us').scrollIntoView({ behavior: 'smooth' });
                handleLinkClick();
                }}
                className="block px-4 py-2 text-primary hover:bg-opacity-50 hover:bg-accent rounded-md hover:scale-105 transition-all duration-300"
            >
                ABOUT US
            </button>
            <button
                onClick={() => {
                document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
                handleLinkClick();
                }}
                className="block px-4 py-2 text-primary hover:bg-opacity-50 hover:bg-accent rounded-md hover:scale-105 transition-all duration-300"
            >
                CONTACTANOS
            </button>
            </div>
        </div>
        </nav>
    );
};

export default Navbar;
